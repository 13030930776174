<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="@/assets/images/logo.jpg">
            </div>
            <h4>Forgot Password</h4>
            <form class="pt-3">
              <div class="form-group">
                <input type="email" class="form-control form-control-lg" v-model="$v.email.$model" id="email" placeholder="Enter or User Name" :class="{ 'is-invalid': $v.email.$error }">
                <span v-if="!$v.email.required" class="text-danger">Please enter email address</span>
              </div>

              <div class="mt-3">
                <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                <button v-else type="button" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" @click="ForgotPassword">Forgot Password</button>
              </div>
            </form>
          </div>
        </div>
        <Footer class="mt-5"/>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
</template>
<script>

import { required, numeric } from "vuelidate/lib/validators";
import Footer from "@/components/Footer.vue";
import { mapActions} from "vuex";
import string from "../constant/strings.js"

export default {
    name:'forgotpassword',
    title: string.PAGE_TITLE_FORGOT_PASSWORD,
  components: {Footer,},

  data() {
    return {
      email:'',
      is_btn_spinner:false
    };
  },

  validations: {    
    email: {required},
  },

  mounted() {
  },

   methods: {
       ...mapActions("hospital",["forgotPasswordData"]),

        ForgotPassword() {
            if (this.$v.email.$invalid) {
                    this.is_btn_spinner = false;
                    return;
            } else {
                  this.is_btn_spinner = true;
                  var bodyFormData = new FormData();
                  bodyFormData.append('email', this.email);
                  this.forgotPasswordData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                        this.$toasted.success(response.message, {duration: 2000,});
                        this.$router.push({ name: "login" });
                  } else {
                        this.$toasted.error(response.message, {duration: 2000,});
                  }
                  });
            }
        },

        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.msg['email'] = 'Please enter a valid email address';
            } else {
                this.msg['email'] = '';
            }
        }
    },
};
</script>